<template>
  <div class="template-footer">
    <h1>
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: "Footer"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>